import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DisableSwipeGestureService {
  constructor(@Inject(DOCUMENT) private document: Document) {}
  disableSwipeGesture() {
    this.document.addEventListener('touchstart', function(e) {

    // is not near edge of view, exit

    if (e.changedTouches[0].pageX > 10 && e.changedTouches[0].pageX < window.innerWidth - 10) return;



    // prevent swipe to navigate gesture

    e.preventDefault();

});
  }
}
