import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {

  constructor(  
      private router: Router,
      private route: ActivatedRoute,
      public alertController: AlertController,
      private store:LocalStoreService) {
      // this.store.hardlogout();
      // this.router.navigate([`started`]);  
   }

  ngOnInit() {
    if (this.store.getRestaurant()) {
      this.store.hardlogout();
      this.logout_modal_Success();
    } else {
      this.store.hardlogout();
      this.logout_modal_UnSuccess();
    }
    
    // this.router.navigate([`started`]);  
  }

  async logout_modal_Success() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Logged Out',//'Please Tap Again',
      message: '<h2>Logged Out Successfully.</h2>',
      backdropDismiss: false,
    });
    (await alert).present();
  }

  async logout_modal_UnSuccess() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Logged Out',//'Please Tap Again',
      message: '<h2>Logout was unsuccessful.</h2>',
      backdropDismiss: false,
    });
    (await alert).present();
  }

}
