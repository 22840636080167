import { Pipe } from '@angular/core';

@Pipe({ name: 'itemname' })
export class SplitItemNamePipe {
    transform(value: string): any {
        try {
          let finalName1 = "";
          let finalName2 = "";
          let finalName = value;
          var splitted = value.split("(");
          console.log(splitted)
              if(splitted[0]){
                finalName1 = splitted[0];
                finalName = finalName1;
              } 
              if(splitted[1]){
                finalName2 = " ("+splitted[1];
                finalName = finalName1 + "<br />"+finalName2;
              } 
          return finalName;
        } catch {
          return value;
        }
      }
}