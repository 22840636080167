import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../services/localstore.service';
import { ModalController } from '@ionic/angular';
import { KioskapiService } from '../services/kioskapi.service';
import { setIntervalTimeOut, DOMAIN_URL } from '../common/api';

@Component({
  selector: 'app-payment-success-modal',
  templateUrl: './payment-success-modal.page.html',
  styleUrls: ['./payment-success-modal.page.scss'],
})
export class PaymentSuccessModalPage implements OnInit {

  success_page_redirection_time_left = 10;
  success_time_left_redirection_counter: any;
  translatedText;
  constructor(
    private store: LocalStoreService, public modalController: ModalController,
    private kiosk_apiService : KioskapiService) { }

  ngOnInit() {
  }
  ionViewWillEnter() {
    this.Page_timer();
    this.getTranslatedText();
  }
  getTranslatedText() {
    this.translatedText = this.store.get('translatedText');
  }

  Page_timer() {
   
    clearInterval(this.success_time_left_redirection_counter);
    this.success_page_redirection_time_left = 10;
    if (this.success_page_redirection_time_left = 10) {
      this.success_time_left_redirection_counter = setInterval(() => {
        this.success_page_redirection_time_left -= 1;
        if (this.success_page_redirection_time_left == 0) {
          clearInterval(this.success_time_left_redirection_counter);
          this.kiosk_apiService.terminal_dismiss();
          this.modalController.dismiss();
          
          // let Autologin = this.store.get('autoLoginUrl')
          // this.store.hardlogout()
          // location.href = DOMAIN_URL + Autologin;
          // return false;
        }
      }, setIntervalTimeOut);
    }
  }

  dismissModal() {
    clearInterval(this.success_time_left_redirection_counter);
    // const feedBackSubmitted = {
    // "dismiss_type": 'retry',
    // "resetPaymentCount" : this.resetCount}
      this.modalController.dismiss();
  }

}
