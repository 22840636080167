import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CartItemDetailPageRoutingModule } from './cart-item-detail-routing.module';

import { CartItemDetailPage } from './cart-item-detail.page'; 

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    CartItemDetailPageRoutingModule
  ],
  declarations: [CartItemDetailPage]
})
export class CartItemDetailPageModule {}
