import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'scan',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  // {
  //   path: 'started',
  //   loadChildren: () => import('./started/started.module').then( m => m.StartedPageModule)
  // },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'payment-method',
    loadChildren: () => import('./payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
  },
  {
    path: 'settlement-complete',
    loadChildren: () => import('./settlement-complete/settlement-complete.module').then( m => m.SettlementCompletePageModule)
  },
  {
    path: 'kioskautologin/:id/:uuid/:tid',
    loadChildren: () => import('./kioskautologin/kioskautologin.module').then( m => m.KioskautologinPageModule),
    pathMatch: 'full'
  },  
  {
    path: 'started1',
    loadChildren: () => import('./started1/started1.module').then( m => m.Started1PageModule)
  },
  {
    path: 'product-combo-detail',
    loadChildren: () => import('./product-combo-detail/product-combo-detail.module').then( m => m.ProductComboDetailPageModule)
  },
  {
    path: 'autologin/1',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'scan',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'cart-list',
    loadChildren: () => import('./cart-list/cart-list.module').then( m => m.CartListPageModule)
  },
  {
    path: 'ella-max-drink-modal',
    loadChildren: () => import('./ella-max-drink-modal/ella-max-drink-modal.module').then( m => m.EllaMaxDrinkModalPageModule)
  },
  {
    path: 'retry-payment',
    loadChildren: () => import('./retry-payment/retry-payment.module').then( m => m.RetryPaymentPageModule)
  },
  {
    path: 'payment-types',
    loadChildren: () => import('./payment-types/payment-types.module').then( m => m.PaymentTypesPageModule)
  },
  {
    path: 'qr-code-methods',
    loadChildren: () => import('./qr-code-methods/qr-code-methods.module').then( m => m.QrCodeMethodsPageModule)
  },
  {
    path: 'qr-scan',
    loadChildren: () => import('./qr-scan/qr-scan.module').then( m => m.QrScanPageModule)
  },
  {
    path: 'payment-success-modal',
    loadChildren: () => import('./payment-success-modal/payment-success-modal.module').then( m => m.PaymentSuccessModalPageModule)
  },
  {
    path: 'no-payment-screen',
    loadChildren: () => import('./no-payment-screen/no-payment-screen.module').then( m => m.NoPaymentScreenPageModule)
  },
  {
    path: 'place-your-cup',
    loadChildren: () => import('./place-your-cup/place-your-cup.module').then( m => m.PlaceYourCupPageModule)
  },
  {
    path: 'cup-detected',
    loadChildren: () => import('./cup-detected/cup-detected.module').then( m => m.CupDetectedPageModule)
  },
  {
    path: 'payment-screen',
    loadChildren: () => import('./payment-screen/payment-screen.module').then( m => m.PaymentScreenPageModule)
  },
  {
    path: 'payment-failed',
    loadChildren: () => import('./payment-failed/payment-failed.module').then( m => m.PaymentFailedPageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./payment-success/payment-success.module').then( m => m.PaymentSuccessPageModule)
  },
  {
    path: 'coffee-ready-to-collect',
    loadChildren: () => import('./coffee-ready-to-collect/coffee-ready-to-collect.module').then( m => m.CoffeeReadyToCollectPageModule)
  },
  {
    path: 'cup-removed',
    loadChildren: () => import('./cup-removed/cup-removed.module').then( m => m.CupRemovedPageModule)
  },
  {
    path: 'get-cup',
    loadChildren: () => import('./get-cup/get-cup.module').then( m => m.GetCupPageModule)
  },
  {
    path: 'return-cup',
    loadChildren: () => import('./return-cup/return-cup.module').then( m => m.ReturnCupPageModule)
  },
  {
    path: '**',
    redirectTo: 'scan'
 },


 

  
  

  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
