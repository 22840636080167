import { Component } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from 'src/environments/environment';
import { DisableSwipeGestureService } from './services/disable-swipe-gesture.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private swipeGestureDisable:DisableSwipeGestureService) {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app)
    // this.swipeGestureDisable.disableSwipeGesture();
  }
}
