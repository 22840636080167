import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ProductComboDetailPageRoutingModule } from './product-combo-detail-routing.module';
import { ProductComboDetailPage } from './product-combo-detail.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
    ProductComboDetailPageRoutingModule
  ],
  declarations: [ProductComboDetailPage]
})
export class ProductComboDetailPageModule {}
