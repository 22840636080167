import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
// import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {

    public pageLoaderStatus: Subject<boolean> = new Subject<boolean>();
    public shouldPageLoad: Subject<boolean> = new Subject<boolean>();
    public pageLoaderText: Subject<any> = new Subject<any>();
    private keepAfterRouteChange = false;

    constructor(
      private router: Router,
      public loadingController: LoadingController,
    ) {
      // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
      router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
              if (this.keepAfterRouteChange) {
                  // only keep for a single route change
                  this.keepAfterRouteChange = false;
              } else {
                  // clear alert messages
                  this.clear();
              }
          }
      });
  }

  async presentLoadingPersistent(message?: string) {
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      //duration: 5000,
      message: '<ion-img src="/assets/image/ella-loader1.gif" alt="loading..."></ion-img>',
      // translucent: true,
      cssClass: 'custom-class ella-loading',
      // backdropDismiss: true
    });
    await loading.present();
    await loading.onDidDismiss();
  }

  async presentHideLoadingPersistent(message?: string) {
    await this.loadingController.dismiss();
  }

  async presentLoading(message?: string) {
    const loading = await this.loadingController.create({
      spinner: 'crescent',
      duration: 2000,
      message: '<ion-img src="/assets/image/Ella_loading.gif" alt="loading..."></ion-img>',
      // translucent: true,
      cssClass: 'custom-class custom-loading',
      // backdropDismiss: true
    });
    await loading.present();
    await loading.onDidDismiss();
  }

  // <ion-router-outlet (activate)="onPageActivated()"></ion-router-outlet>
  async dismissLoading() {
    const topLoader = await this.loadingController.getTop();
    if (topLoader) {
      await topLoader.dismiss();
    }
  }

  clear() {
      // clear alerts
      this.pageLoaderText.next('');
  }

  pageLoader(value: boolean,message?: string) {
    this.pageLoaderStatus.next(value);
    if(value === true) {this.presentLoading(message);}
  }

  pageLoaderPersistent(value: boolean,message?: string) {
    this.pageLoaderStatus.next(value);
    if(value === true) {this.presentLoadingPersistent(message);}
    else{  this.presentHideLoadingPersistent(message); }
  }

  setLoaderText(text?: string) {
    this.pageLoaderText.next(text);
  }

  getLoaderText(): Observable<any> {
    return this.pageLoaderText.asObservable();
  }

  refreshPage(value){
      this.shouldPageLoad.next(value);
  }
}
