import { Pipe } from '@angular/core';

@Pipe({ name: 'category' })
export class MenuCategoryPipe {
    transform(value: string): any {
        try {
          let finalCategory = value;
          var splitted = value.split("__");
              if(splitted[1]){
                finalCategory = splitted[1]
              } 
          return finalCategory;
        } catch {
          return value;
        }
      }
}