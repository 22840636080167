//translate-config.service.ts
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStoreService } from './localstore.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  constructor(
    private translate: TranslateService,
    private store: LocalStoreService,
  ) { }

  getDefaultLanguage(){
    // let language = this.translate.getBrowserLang();
    let language = this.store.getLanguageCode();
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
  }

}